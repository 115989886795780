import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import { CustomExportButton } from './DeliveryRequestsInputs';

const ListContent = () => {
    const {
        data,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    const now = Date.now();

    return (
        <>
            {data.length < 1 && <Box sx={{ margin: '1rem' }}><h5>Заказы на доставку появятся здесь. Пока заказов нет.</h5></Box>}
            <List>
                {data.map(obj => (
                    <RecordContextProvider key={obj.id} value={obj}>
                        <ListItem
                            //button
                            //component={Link}
                            to={localStorage.getItem('role_id') <= 2 || localStorage.getItem('id') == obj.manager_account_id ? `/deliveryRequests/${obj.id}` : '/deliveryRequests'}
                        >
                            <Box display="flex" flex="1" >
                            <ListItemText
                                primary={`Заказ №${obj.number}`}
                                secondary={new Date(obj.created_at).toLocaleString("RU-ru")}
                            />
                            </Box>
                            <Box display="flex" flex="1" >
                            <ListItemText
                                primary={obj.name}
                                secondary={obj.phone}
                            />
                            </Box>
                            <Box display="flex" flex="1" >
                            <ListItemText
                                primary={<Link to={`https://t.me/${obj.tg_nickname.slice(1)}`}>{obj.tg_nickname}</Link>}
                                secondary={obj.address}
                            />
                            </Box>
                            <Box display="flex" flex="1" >
                            <EditButton label="Детали заказа" />
                            </Box>
                            <Box display="flex" flex="1" >
                            <CustomExportButton record={obj} />
                            </Box>
                            {/*localStorage.getItem('role_id') <= 2 || localStorage.getItem('id') == obj.manager_account_id && <EditButton />*/}
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
        </>
    );
};

const ListActions = () => (
    <TopToolbar>
       <CreateButton
            variant="contained"
            label="Добавить заказ вручную"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

const DeliveryRequestsList = () => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            sx={{ marginTop: '1rem' }}
            actions={localStorage.getItem('role_id') <= 2 ? <ListActions /> : null}
            perPage={50}
            pagination={<Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />}
            empty={false}
            sort={{ field: 'created_at', order: 'DESC' }}
        >
            <ListContent />
        </RaList>
    ) : null;
};

export default DeliveryRequestsList;