import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    ArrayInput,
    SimpleFormIterator,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext,
    NumberInput,
    DateInput,
    useEditContext
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Divider, Box, Grid, IconButton, Button  } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { endpoint } from '../App';
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';

export const DeliveryRequestsInputs = () => {
    const editContext = useEditContext();
    const navigate = useNavigate();

    return (<Box flex="1" mt={-1} gap='1rem' >

        <Box display="flex" flexDirection="row">
			<IconButton
				  onClick={() => navigate(-1)}
				  size="small"
				>
				  <KeyboardBackspaceIcon />
			</IconButton>
			{editContext.record ? <h3>Заказ от клиента №{editContext.record.number}</h3> : <h3>Создание заказа</h3>}
            {editContext.record && <Box sx={{ marginLeft: '1rem', marginBottom: '1rem' }}><CustomExportButton record={editContext.record}/></Box> }
		</Box>

        <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
        <TextInput source="name" sx={{ minWidth: '40%' }} label="Имя" validate={required()} />
        <TextInput source="phone" sx={{ minWidth: '40%' }} label="Номер телефона" validate={required()} />
        <TextInput source="address" sx={{ minWidth: '40%' }} label="Адрес доставки" validate={required()}/>
        <TextInput source="tg_nickname" sx={{ minWidth: '40%' }} label="Телеграм @nickname" validate={required()}/>

        <SelectInput 
        source="shop" 
        label="Магазин"
        sx={{ minWidth: '40%' }} 
        choices={[
            { id: 'lemana', name: 'Лемана ПРО' },
            { id: 'vseinstrumenti', name: 'ВсеИнструменты.ру' },
        ]} 
        validate={required()} 
        />


        <ArrayInput label="Ссылки на корзину" source="cart_links" >
            <SimpleFormIterator inline disableClear disableReordering fullWidth>
            <TextInput label="Ссылка" fullWidth/>
            </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput label="Заказ" source="cart_items">
            <SimpleFormIterator inline disableClear disableReordering>
                <TextInput label="Название товара" source="name" fullWidth/>
                <TextInput label="Артикул" source="id"/>
                <NumberInput label="Количество" source="quantity" />
                <NumberInput label="Цена магазина, руб." source="price" />
                <NumberInput label="Наша цена, руб." source="discountedPrice" />
            </SimpleFormIterator>
         </ArrayInput>

        </Box>

    </Box>);
};

// Custom export button component
export const CustomExportButton = ({ record, ...props }) => {

    const handleClick = () => {
  
      const reorderedDataToExport = record.cart_items.map(item => ({
        артикул: item.id,
        количество: item.quantity,
        наименование: item.name
      }));

        // Создание листа Excel
      const ws = XLSX.utils.json_to_sheet(reorderedDataToExport);
  
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Лист 1');
      XLSX.writeFile(wb, `Заказ №${record.number} - Выгрузка из CRM.xlsx`);
    };
  
    return (
      <Button onClick={handleClick} label="Выгрузить в Excel" variant="outlined" size="small" color="success" startIcon={<FileUploadIcon />}>
        Выгрузить в Excel
      </Button>
    );
  };